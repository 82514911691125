import React, { useEffect, useState } from 'react'
import './Pages CSS/Homepage.css'
import {NavLink } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y } from 'swiper/modules';
import StickyNavbar  from '../Navbar/StickyNavbar';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Footer from '../Footer/Footer'
import aeps from './Images Homepage/aeps.png'
import s1 from './Images Homepage/service-1.jpg'
import s2 from './Images Homepage/service-2.jpg'
import s3 from './Images Homepage/service-3.jpg'
import flightBookingImage from './Images Homepage/flightbook.png'
import hotelBookingImage from './Images Homepage/hotelbook.png'
// import s4 from './Images Homepage/service-4.jpg'
import s5 from './Images Homepage/servicebus.png'
import s6 from './Images Homepage/service-6.jpg'
import s8 from './Images Homepage/serviceflight.png'
import s7 from './Images Homepage/servicebus.png'
import s9 from './Images Homepage/servicehotel.png'
// import s8 from './Images Homepage/bbps.png'
import wmoreimg from './Images Homepage/wh-img-1.jpg'
import recharge from './Images Homepage/recharge.png'
import busBookingImage from './Images Homepage/Busbooking.png'
// import { AiTwotoneAppstore } from "react-icons/ai";
import abimgr from './Images Homepage/aboutsecimg.png'
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

export default function HomePage() {
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    if (currentScrollY > lastScrollY) {
      // Scrolling down
      setShowNavbar(false);
    } else {
      // Scrolling up
      setShowNavbar(true);
    }

    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: true });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [inView, controls]);

  const textVariant = {
    hidden: {
      opacity: 0,
      y: 20,
    },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.1, // Delay for each letter
        duration: 0.3,
      },
    }),
  };

  return (
    <div className='homecompcont'> 
    
    {/* Navbar */}
    <div className={`sticky-navbar ${showNavbar ? 'visible' : 'hidden'}`}>
      <StickyNavbar />
    </div>
      
    {/* Slider Section */}
    <section className="wrapper w-full mt-16 pb-12">
      <Carousel
        showThumbs={false}
        showStatus={false}
        infiniteLoop
        useKeyboardArrows
        autoPlay
        interval={5000}
      >
        {/* Slide 1 */}
        <div className="swiperslide1">
          <div className="wrapcont w-full md:w-3/4 mx-auto px-4 md:px-0">
            <div className="itemwrap mt-5 flex flex-col md:flex-row w-full bg-yellow-300 p-4 md:p-8">
              <div className="homtextcont flex flex-col md:flex-row">
                <div className="hgfd text-black grid md:mr-8 animate-fade-in-left">
                  <span className="w-full">
                    <p className="text-2xl mt-4 mb-4">AEPS</p>
                    <p className="text-4xl">Aadhar Enabled Payment System</p>
                    <p className="text-lg mt-8 w-full md:w-96">
                      Aadhaar Enabled Payment System (AEPS) is a sort of installment framework that relies on the Unique Identification Number and permits Aadhaar card holders to make financial transactions through Aadhaar-based authentication.
                    </p>
                  </span>
                </div>
              </div>
              <div className="homimgcont flex justify-center mt-4 md:mt-0 animate-slide-in-right">
                <img src={aeps} alt="Aadhar Enabled Payment System" className="w-full md:w-auto" />
              </div>
            </div>
          </div>
        </div>

        {/* Slide 2 */}
        <div className="swiperslide2">
          <div className="wrapcont w-full md:w-3/4 mx-auto px-4 md:px-0">
            <div className="itemwrap mt-5 flex flex-col md:flex-row w-full bg-blue-300 p-4 md:p-8">
              <div className="homtextcont flex flex-col md:flex-row">
                <div className="hgfd text-black grid md:mr-8 animate-fade-in-left">
                  <span className="w-full">
                    <p className="text-2xl mt-4 mb-4">Flight Booking</p>
                    <p className="text-4xl">Book Flights Conveniently Anytime, Anywhere!</p>
                    <p className="text-lg mt-8 ml-12 w-full md:w-96">
                      SunPay offers seamless flight booking services, making it easier than ever to plan your travel. Choose from a variety of airlines and enjoy exclusive deals.
                    </p>
                  </span>
                </div>
              </div>
              <div className="homimgcont flex justify-center animate-slide-in-right">
                <img src={flightBookingImage} alt="Flight Booking Services" className="w-full md:w-auto" />
              </div>
            </div>
          </div>
        </div>

        {/* Slide 3*/}
        <div className="swiperslide3">
          <div className="wrapcont w-full md:w-3/4 mx-auto px-4 md:px-0">
            <div className="itemwrap mt-5 flex flex-col md:flex-row w-full bg-green-300 p-4 md:p-8">
              <div className="homtextcont flex flex-col md:flex-row">
                <div className="hgfd text-black grid md:mr-8 animate-fade-in-left">
                  <span className="w-full">
                    <p className="text-2xl mt-4 mb-4">Hotel Booking</p>
                    <p className="text-4xl">Book Comfortable Stays Anywhere, Anytime!</p>
                    <p className="text-lg mt-8 w-full ml-20 md:w-96">
                      With SunPay, find and book your perfect hotel effortlessly. Explore a wide range of accommodations at competitive prices to suit every traveler's needs.
                    </p>
                  </span>
                </div>
              </div>
              <div className="homimgcont flex justify-center animate-slide-in-right">
                <img src={hotelBookingImage} alt="Hotel Booking Services" className="w-full md:w-auto" />
              </div>
            </div>
          </div>
        </div>

        {/* Slide 3 */}
        <div className="swiperslide3">
          <div className="wrapcont w-full md:w-3/4 mx-auto px-4 md:px-0">
            <div className="itemwrap mt-5 flex flex-col md:flex-row w-full bg-blue-300 p-4 md:p-8">
              <div className="homtextcont flex flex-col md:flex-row">
                <div className="hgfd text-black grid md:mr-8 animate-fade-in-left">
                  <span className="w-full">
                    <p className="text-2xl mt-4 mb-4">Bus Booking</p>
                    <p className="text-4xl">Book Hassle-Free Bus Rides Anytime, Anywhere!</p>
                    <p className="text-lg mt-8 w-full ml-20 md:w-96">
                      With SunPay, plan your road trips effortlessly. Discover a wide range of bus options and book your journey conveniently at affordable prices.
                    </p>
                  </span>
                </div>
              </div>
              <div className="homimgcont flex justify-center animate-slide-in-right">
                <img src={busBookingImage} alt="Bus Booking Services" className="w-full md:w-auto" />
              </div>
            </div>
          </div>
        </div>


        {/* Slide 3*/}
      {/*  <div className="swiperslide3">
          <div className="wrapcont w-full md:w-3/4 mx-auto px-4 md:px-0">
            <div className="itemwrap mt-5 flex flex-col md:flex-row w-full bg-stone-300 p-4 md:p-8">
              <div className="homtextcont ml-12 flex flex-col md:flex-row">
                <div className="hgfd text-black grid md:mr-8 animate-fade-in-left">
                  <span className="w-full">
                    <p className="text-2xl mt-4 mb-4">Recharge</p>
                    <p className="text-4xl">Mobile Recharge & Utility</p>
                    <p className="text-lg mt-8 w-full md:w-96">
                      SunPay includes virtually everything you need to expand upon your current service offerings or start your own Multi-services business.
                    </p>
                  </span>
                </div>
              </div>
              <div className="homimgcont flex ml-20 justify-center animate-slide-in-right">
                <img src={recharge} alt="Flight Booking Services" className="w-full md:w-auto" />
              </div>
            </div>
          </div>
        </div> */}

      </Carousel>
    </section>

          
    {/* Appointment line content */}
    <div ref={ref} className="wrapbeltext bg-gray-100 p-4">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <div className="leftwrapbeltxt mb-4 md:mb-0 text-center md:text-left">
          <motion.div className="wrpbolhead text-lg md:text-2xl font-semibold mb-2 md:mb-4">
            {"Looking for a Solution for your Business?".split('').map((char, index) => (
              <motion.span key={index} custom={index} initial="hidden" animate={controls} variants={textVariant}>
                {char === ' ' ? '\u00A0' : char} {/* Ensure spaces render */}
              </motion.span>
            ))}
          </motion.div>
          <motion.div className="wrpbeltxt text-sm md:text-base" initial="hidden" animate={controls} variants={{
            hidden: { opacity: 0, y: 20 },
            visible: { opacity: 1, y: 0, transition: { delay: 2, duration: 0.5 } },
          }}>
            Make an appointment today with one of our solution experts
          </motion.div>
        </div>

        <motion.div className="rightwrapbeltxt" initial="hidden" animate={controls} variants={{
          hidden: { opacity: 0, y: 20 },
          visible: { opacity: 1, y: 0, transition: { delay: 2.5, duration: 0.5 } },
        }}>
          <div className="wrapbelbtn text-center md:text-right">
            <button
              type="submit"
              className="bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700 transition duration-300"
            >
              MAKE AN APPOINTMENT
            </button>
          </div>
        </motion.div>
      </div>
    </div>

    {/* About Section */}
      <section className='aboutussecdiv bg-white flex flex-wrap pb-12'>
          <div className='w-full md:w-3/5'>
            <div className='aboutussechead pt-8 text-center md:text-left'>
              <h1 className='text-5xl md:text-7xl text-orange-500 rounded-lg px-4 md:pl-20 w-full mt-8 break-all inline-block'>
                About us
              </h1>
              <h1 className='text-5xl md:text-7xl text-green-700 rounded-lg px-4 md:pl-4 w-full mt-8 break-all inline-block'>
              
              </h1>
            </div>
            <div className="aboutsectext text-center md:text-left ml-4 md:ml-8 text-xl px-4 md:px-10 mt-6 mb-10">
              Sunpay has emerged as one of the most popular platforms for booking travel services efficiently and affordably. At Sunpay, we're revolutionizing the way you plan your trips, making flight bookings, bus bookings, hotel bookings, and travel bookings easier, faster, and more affordable than ever before.
              <br />Our mission is to empower you to explore the world with the utmost confidence, security, and convenience. Whether you're planning a business trip or a family vacation, Sunpay ensures a seamless and hassle-free experience from start to finish.
              <br />
              <NavLink to="/about-us">
                <button className='w-48 h-12 mt-7 text-white bg-gradient-to-r from-orange-500 to-green-500 rounded-lg hover:opacity-80'>
                  Read More
                </button>
              </NavLink>
            </div>
          </div>
          <div className='w-full md:w-2/5 container flex justify-center md:justify-end'>
            <div className='aboutsecimg inline-block'>
              <img src={abimgr} alt="About Us" className='inline-block p-0 max-w-full h-auto'/>
            </div>
          </div>
      </section>

    {/* More about Sunpay Section */}
      <section className='moresunp bg-stone-400 pb-16 px-8 '>
        <div className="moresunp mt-8 flex flex-col lg:flex-row">
            <div className='leftmoresunp w-full lg:w-2/5 lg:ml-20 mt-8 text-center lg:mt-16'>
              <h1 className='text-5xl text-green-700'><b>
              We Are Here To Help You <h1 className='text-5xl text-orange'>Build Your Business</h1>
              </b></h1>

              <p className='mt-6 text-xl w-full overflow-wrap'>
                Welcome to SunPay, your trusted partner in travel services. SunPay is a well-known name in the travel and booking industry. We believe in providing the best services to make our platform reliable and customer-friendly. Our offerings include top-notch solutions for **flight booking, hotel booking, bus booking, and travel booking** across the globe.
              </p>
              <p className='mt-6 text-xl w-full overflow-wrap'>
                Become a part of this innovative platform with minimal investment. Start your journey today for a brighter future. Establishing a higher standard in travel services is key to shaping the global travel market. At SunPay, we provide a robust platform and an efficient booking system, setting new benchmarks in the travel and booking industry.
              </p>

              <br />
              <NavLink to="/about-us" > 
                        <button className='w-48 h-12 mt-7 text-white bg-gradient-to-r from-orange to-green-500 rounded-lg hover:opacity-50'>
                            Read More 
                        </button>
                    </NavLink>  
              </div>
              <div className='rightmoresunp w-full lg:w-1/2 text-center lg:ml-16 mt-8'>
                <div className='boxcontrsunp grid grid-cols-1 sm:grid-cols-2 gap-y-8'>
                  <div className="rmoresunpitem w-full sm:w-72 h-72 mx-auto sm:ml-4">
                    <div className="rmoresunpitemicon rounded-full ml-6 w-20 h-20 bg-green-700 z-40 absolute"></div>
                    <div className='rsunpservnamecont mt-12 pt-8 w-full rounded-2xl  px-12 bg-white relative overflow-hidden'>
                      <div className='text-black pb-8'>
                        <h1 className='mt-4 text-2xl sm:text-3xl ml-6 font-bold'>Easy Integration</h1>
                        <p className='mt-4 ml-6 overflow-wrap break-word'>
                          Sign up and go with easy integration feature for your web and mobile applications.
                        </p>
                        <br />
                        <NavLink to="/services" className='mt-4 ml-6 text-lg w-fit text-violet-950 hover:bg-blue-800 hover:text-white'><b>Read More</b></NavLink>
                      </div>
                    </div>
                  </div>

                  <div className="rmoresunpitem w-full sm:w-72 h-72 mx-auto sm:ml-4">
                    <div className="rmoresunpitemicon rounded-full ml-6 w-20 h-20 bg-green-700 z-40 absolute"></div>
                    <div className='rmoresunpservnamecont mt-12 pt-8 w-full rounded-2xl  px-12 bg-white relative overflow-hidden'>
                      <div className='text-black pb-8'>
                        <h1 className='mt-4 text-2xl sm:text-3xl ml-6 font-bold'>Faster Response</h1>
                        <p className='mt-4 ml-6 overflow-wrap break-word'>
                          We use RESTFul APIs which deliver fastest response over requests made from web or mobile devices.
                        </p>
                        <br />
                        <NavLink to="/services" className='mt-4 ml-6 text-lg w-fit text-violet-950 hover:bg-blue-800 hover:text-white'><b>Read More</b></NavLink>
                      </div>
                    </div>
                  </div>

                  <div className="rmoresunpitem w-full sm:w-72 h-72 mx-auto sm:ml-4">
                    <div className="rwhitemicon rounded-full ml-6 w-20 h-20 bg-green-700 z-40 absolute"></div>
                    <div className='rmoresunpservnamecont mt-12 pt-8 w-full rounded-2xl  px-12 bg-white relative overflow-hidden'>
                      <div className='text-black pb-8'>
                        <h1 className='mt-4 text-2xl sm:text-3xl ml-6 font-bold'>24/7 Support</h1>
                        <p className='mt-4 ml-6 overflow-wrap break-word'>
                          Our customer service is best in class and committed to serve you 24x7 for your queries and questions.
                        </p>
                        <br />
                        <NavLink to="/services" className='mt-4 ml-6 text-lg w-fit text-violet-950 hover:bg-blue-800 hover:text-white'><b>Read More</b></NavLink>
                      </div>
                    </div>
                  </div>

                  <div className="rmoresunpitem w-full sm:w-72 h-72 mx-auto sm:ml-4">
                    <div className="rwhitemicon rounded-full ml-6 w-20 h-20 bg-green-700 z-40 absolute"></div>
                    <div className='rmoresunpservnamecont mt-12 pt-8 w-full rounded-2xl  px-12 bg-white relative overflow-hidden'>
                      <div className='text-black pb-8'>
                        <h1 className='mt-4 text-2xl sm:text-3xl ml-6 font-bold'>For All Platforms</h1>
                        <p className='mt-4 ml-6 overflow-wrap break-word'>
                          No worries of technology your application is built on, we support all of them.
                        </p>
                        <br />
                        <NavLink to="/services" className='mt-4 ml-6 text-lg w-fit text-violet-950 hover:bg-blue-800 hover:text-white'><b>Read More</b></NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

        </div>
      </section> 

    {/* Service Section */}
    <section className='servicesectionhome bg-white pt-12 pb-16'>
      <div className="homservseccont w-full">
        <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl ml-4 sm:ml-8 md:ml-12 text-gray-900 font-bold"><b>Our Services</b></h1>
        <h1 className="text-lg sm:text-xl md:text-2xl lg:text-3xl ml-4 sm:ml-8 md:ml-12 text-slate-200">Take Business Services From Our Experienced Staff</h1>

        <div className='homeservslidecont mx-4 mt-6'>
          <Swiper
            className='rounded-lg'
            modules={[Navigation, Pagination, A11y]}
            spaceBetween={20}
            slidesPerView={1}
            centeredSlides={true}
            navigation
            pagination={{ clickable: true }}
            breakpoints={{
              640: { slidesPerView: 1 },
              768: { slidesPerView: 2 },
              1024: { slidesPerView: 3 },
            }}
            initialSlide={0} // Set the first slide as active
          >
            <SwiperSlide>
              <div className="swiperslide1 px-4 sm:px-6 md:px-8 py-4">
                <div className='swiperslide-content rounded-3xl p-4 sm:p-6 md:h-[600px] h-[600px] bg-white hover:bg-blue-700 hover:text-white'>
                  <img src={s6} alt="" className='w-full h-40 sm:h-48 md:h-56 lg:h-64 object-cover' />
                  <h1 className='font-black text-2xl sm:text-3xl md:text-4xl mt-4'>Travel Booking</h1>
                  <p className='text-lg mt-4'>
                    Travel API is fundamentally a set of web services to access travel deals from various consolidators. GDS, third-party flight APIs, and hotel APIs are used by travel agencies to access deals online.
                  </p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="swiperslide1 px-4 sm:px-6 md:px-8 py-4 ">
                <div className='swiperslide-content rounded-3xl p-4 sm:p-6 md:h-[600px] h-[600px] bg-white hover:bg-blue-700 hover:text-white'>
                  <img src={s9} alt="" className='w-full h-40 sm:h-48 md:h-56 lg:h-64 object-cover' />
                  <h1 className='font-black text-2xl sm:text-3xl md:text-4xl mt-4'>Hotel Booking</h1>
                  <p className='text-lg mt-4'>
                    We provide hotel booking services in India for various accommodations, including budget hotels, luxury hotels, and resorts.
                  </p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="swiperslide1 px-4 sm:px-6 md:px-8 py-4">
                <div className='swiperslide-content rounded-3xl p-4 sm:p-6 md:h-[600px] h-[600px] bg-white hover:bg-blue-700 hover:text-white'>
                  <img src={s8} alt="" className='w-full h-40 sm:h-48 md:h-56 lg:h-64 object-cover' />
                  <h1 className='font-black text-2xl sm:text-3xl md:text-4xl mt-4'>Flight Booking</h1>
                  <p className='text-lg mt-4'>
                    We provide online flight booking services. Our service covers major domestic and international airlines, ensuring a seamless booking experience.
                  </p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="swiperslide1 px-4 sm:px-6 md:px-8 py-4">
                <div className='swiperslide-content rounded-3xl p-4 sm:p-6 md:h-[600px] h-[600px] bg-white hover:bg-blue-700 hover:text-white'>
                  <img src={s7} alt="" className='w-full h-40 sm:h-48 md:h-56 lg:h-64 object-cover' />
                  <h1 className='font-black text-2xl sm:text-3xl md:text-4xl mt-4'>Bus Booking</h1>
                  <p className='text-lg mt-4'>
                    We provide online bus booking services. Our service covers major bus operators across the country, ensuring a seamless booking experience.
                  </p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </section>


    {/* More About Section */}
    <section className='whysunp bg-slate-50'>
      <div className='whysunpcont md:flex flex-col md:flex-row pt-12 pb-12 px-4 sm:px-8 md:px-12 lg:px-24'>
        <div className='lwhysunp w-full md:w-1/2'>
          <p className='text-red-600 text-lg sm:text-xl'>WHY CHOOSE US?</p><br />
          <h1 className='text-3xl sm:text-4xl font-black w-full sm:w-5/6'>
            We Offer Best Professional Services For Your Business
          </h1>
          <div className='leftwhysunpitemcont mt-8 sm:mt-12'>
            <div className='lwhysunpitem w-full sm:w-3/4 mb-4 py-4 gap-4 flex'>
              <div className='itemicon'>
                {/* Add icon here */}
              </div>
              <div className="itemtext pr-2">
                <h1 className='text-xl sm:text-2xl'>B2B Platform</h1>
                <p className='text-sm sm:text-base'>
                  Build your own Retailer Distributor Network with bug free mobile recharge software.
                </p>              
              </div>
            </div>

            <div className='lwhysunpitem w-full sm:w-3/4 mb-4 py-4 gap-4 flex'>
              <div className='itemicon'>
                {/* Add icon here */}
              </div>
              <div className="itemtext pr-2">
                <h1 className='text-xl sm:text-2xl'>B2C Portals</h1>
                <p className='text-sm sm:text-base'>
                  Online recharge portal connected directly with mobile, DTH, data card, Post Paid bill payment, electricity and utility payment platforms.
                </p>
              </div>
            </div>

            <div className='lwhysunpitem w-full sm:w-3/4 py-4 gap-4 flex'>
              <div className='itemicon'>
                {/* Add icon here */}
              </div>
              <div className="itemtext pr-2">
                <h1 className='text-xl sm:text-2xl'>Android</h1>
                <p className='text-sm sm:text-base'>
                  Multi recharge software includes Android application as standard feature of software. Publish your own brand name on Playstore.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className='rwhysunpimg mt-4 md:mt-12 w-full md:w-1/2 border-4 border-zinc-600'>
                <img src={wmoreimg} alt="" className='w-full h-[500px]'/>
          <div className='text-2xl sm:text-3xl md:text-4xl lg:text-5xl bg-white text-center bottom-0 w-full py-4'>
            100% Trusted Company
          </div>
        </div>
      </div>
    </section>

    {/* Testimonials */}
    <section className='Testimonials bg-slate-500 px-4 sm:px-8 py-10'>
      <div className='testimonials text-center'>
        
        <div className="testheading text-3xl sm:text-4xl md:text-5xl text-white font-extrabold pt-6">
          See What Clients Are Saying
        </div>
        <hr className="solheadingline mx-auto w-1/2 sm:w-1/3 md:w-1/4 mt-4" />

        <p className="testsubheading font-extrabold text-xl sm:text-2xl md:text-3xl text-gray-100 mt-4">
          We are very proud of the service we provide and stand by every service we carry.
        </p>

        <p className="testsublighttext text-white mt-8 text-lg sm:text-xl md:text-2xl">
          Read our testimonials from our happy customers.
        </p>

        <div className="reviewcontdiv mt-12">
          <Swiper
            modules={[Navigation, Pagination, A11y]}
            spaceBetween={20}
            slidesPerView={1} // Default to showing 1 slide at a time
            centeredSlides={true} // Center the active slide
            navigation
            pagination={{ clickable: true }}
            breakpoints={{
              640: { slidesPerView: 1 }, // Show 1 slide for screens 640px and below
              768: { slidesPerView: 2 }, // Show 2 slide for screens between 641px and 768px
              1024: { slidesPerView: 3 }, // Show 3 slides for screens above 1024px
            }}
            initialSlide={1} // Set initial slide to index 1
            onSlideChange={(swiper) => {
              // Custom logic can be added here if needed
            }}
          >
            <SwiperSlide>
              <div className="revcont w-full p-4 h-[350px] border-2 border-gray-300 bg-white hover:bg-blue-600 hover:text-white rounded-lg">
                <p className='revtxt px-2 text-sm sm:text-base md:text-lg'>
                  "In the world of digital finance, security is my top priority. SunPay has earned my trust with their robust security features and transparent policies. I feel confident knowing that my data is protected and that they adhere to the highest standards of compliance. Plus, the regular updates and security checks give me peace of mind."
                </p>
                <br />
                <p className="nam text-lg sm:text-xl md:text-2xl mt-4">
                  David Carter
                </p>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="revcont w-full p-4 h-[350px] border-2 border-gray-300 bg-white hover:bg-blue-600 hover:text-white rounded-lg">
                <p className='revtxt px-2 text-sm sm:text-base md:text-lg'>
                  "I’ve been using this fintech service for several months now, and it has significantly simplified my financial management. The user interface is intuitive, and the advanced security features give me peace of mind."
                </p>
                <br />
                <p className="nam text-lg sm:text-xl md:text-2xl mt-4">
                  Sarah Lee
                </p>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="revcont w-full p-4 h-[350px] border-2 border-gray-300 bg-white hover:bg-blue-600 hover:text-white rounded-lg">
                <p className='revtxt px-2 text-sm sm:text-base md:text-lg'>
                  "As a growing startup, finding a reliable financial partner was crucial. SunPay provided us with a scalable solution that perfectly fits our needs. Their analytics tools have given us valuable insights, allowing us to make informed financial decisions. We’ve saved both time and money thanks to their innovative approach!"
                </p>
                <br />
                <p className="nam text-lg sm:text-xl md:text-2xl mt-4">
                  Mukul Pratap
                </p>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="revcont w-full p-4 h-[350px] border-2 border-gray-300 bg-white hover:bg-blue-600 hover:text-white rounded-lg">
                <p className='revtxt px-2 text-sm sm:text-base md:text-lg'>
                  "As a small business owner, managing finances used to be a headache. But with SunPay, the process has become so much simpler. The platform is intuitive and easy to navigate, even for someone like me who isn't very tech-savvy. The features are exactly what I need, and I can access everything from my phone. Highly recommend it to anyone looking to streamline their financial operations!"
                </p>
                <br />
                <p className="nam text-lg sm:text-xl md:text-2xl mt-4">
                  Michael Brown
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="revcont w-full p-4 h-[350px] border-2 border-gray-300 bg-white hover:bg-blue-600 hover:text-white rounded-lg">
                <p className='revtxt px-2 text-sm sm:text-base md:text-lg'>
                  "I’ve been using SunPay's service for several months now, and it has significantly simplified my financial management. The user interface is intuitive, and the advanced security features give me peace of mind."
                </p>
                <br />
                <p className="nam text-lg sm:text-xl md:text-2xl mt-4">
                  Jessica Williams
                </p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </section>

    {/* Email Subscription */}
    <section className='emailsubscribe p-4 pt-8'>
      <div className='emailseccontainer flex flex-col lg:flex-row gap-4 lg:gap-2'>
        
        <div className="emaisectext px-4 lg:pl-8 text-center lg:text-left">
          <p className="emailsecfirstline text-lg sm:text-xl font-extrabold">
            Don't miss out on the latest updates relevant to you.
          </p>
          <p className="emailsecsecondline text-2xl sm:text-3xl lg:text-4xl font-black mt-4">
            Subscribe now and be a part of our community
          </p>
          <p className="emailsecthirdline text-base text-slate-200 sm:text-lg lg:text-xl mt-4">
            Subscribing is quick and easy. Just enter your email address below and <br className='hidden sm:block' />
            click <b>'Subscribe'</b> to start receiving our informative newsletter.
          </p>
        </div>

        <div className="textbox mt-8 lg:mt-12 text-center  md:flex gap-4 justify-center lg:justify-start">
          <input type="text" name="emailsubscbox" className="emailsubsbox h-12 lg:w-auto rounded-md bg-white p-2 sm:p-3 text-lg"/>

          <button type="submit" className='bg-white 750px:mt-8 border-2 h-12 border-black text-xl sm:text-2xl p-2 sm:p-3 px-4 sm:px-5 rounded-xl hover:bg-blue-600 hover:text-white'>
            <b>SUBSCRIBE NOW</b>
          </button>
        </div>

        
        
      </div>
    </section>

    {/* Footer */}
    <section className='Footer'>
      <Footer/>
    </section>
  </div>
  )
} 
